import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
//import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import LogoImage from "../../logo512.png";
//import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import iyzicoLogo from "images/logo_band_colored@1X.png";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12 flex flex-col items-center lg:items-start`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const RevenueImage = tw.img`mt-6 w-64 sm:w-72 md:w-80 lg:w-96 mx-auto`;
//const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
//const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
//const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Ana Sayfa</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/aboutus">Hakkımızda</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Ürün</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="https://app.feedup.ai/login" target="_blank" rel="noopener noreferrer">
                  Giriş Yap
                </Link>              
              </LinkListItem>
              <LinkListItem>
                <Link href="https://app.feedup.ai/dashboard" target="_blank" rel="noopener noreferrer">
                    Kayıt ol
                </Link>   
              </LinkListItem>
              <LinkListItem>
                <Link href="/pricing">Fiyatlarımız</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>İLETİŞİM</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/contactus">Bizimle İletişime Geçin</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Yasal</ColumnHeading>
            <LinkList>

              <LinkListItem>
                <Link href="/privacypolicy2">Gizlilik Politikası</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/consumerright">Tüketici Hakları</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/kvkk">KVKK Aydınlatma Metni</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/kvkkform">KVKK Bilgi Talep Formu</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/messageinform">Ticari Elektronik Bilgilendirme</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>GELİRLERİNİZİ ARTTIRIN</ColumnHeading>
              <SubscribeText>
              Müşterilerinizin yorumlarını cevapsız bırakmayın. İşletmenizin gelirlerini haftalık ve aylık hazırlanan yüksek kaliteli raporlar sayesinde arttırın.              </SubscribeText>
              <RevenueImage src={iyzicoLogo} alt="Gelirlerinizi Arttırın" />
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Feedup A.Ş.</LogoText>
          </LogoContainer>
          <CopywrightNotice>&copy; 2024 Feedup A.Ş. Tüm Hakları Saklıdır.</CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink 
              href="https://www.instagram.com/feedupai/" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink 
              href="https://www.linkedin.com/in/feedup-ai-bb9200326/?originalSubdomain=tr" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <LinkedinIcon />
            </SocialLink>
            <SocialLink 
              href="https://www.youtube.com/@FeedupAI" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
